<template>
  <!-- 倾听首页 -->
  <div id="app" style="background: #f4f4f4">
    <div class="harken-ban">
      <img src="@/assets/img/harken-ban.jpg" />
    </div>
    <div class="harken-func">
      <div class="cson-container">
        <ul class="clearfix">
          <li
            v-for="(item, index) in tabbars"
            :key="index"
            :class="index == TabCur ? 'active' : ''"
            :data-id="index"
            @click="tabSelect(item, index)"
          >
            <img v-if="index == TabCur" :src="item.iconCur" />
            <img v-if="index != TabCur" :src="item.icon" />
            <p>{{ item.label }}</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="harken-con cson-container clearfix" ref="sugList">
      <div class="harken-le">
        <div class="harken-content">
          <div class="shead">
            <div class="bread">倾听 > {{ activeTitle }}</div>
            <div class="list-container">
              <div class="list">
                <div
                  v-for="(item, index) in sugList"
                  :key="index"
                  class="list-box"
                >
                  <div class="thead">
                    <div class="line1">
                      <span>{{ (currentPage - 1) * 10 + index + 1 }}</span>
                      <p>
                        <router-link :to="`/harkenDetail?id=${item.id}`"
                          >{{ item.questionTitle }}
                        </router-link>
                      </p>
                    </div>
                    <div
                      :class="[
                        'tags',
                        item.status && item.status.value != 1
                          ? 'unpublished'
                          : '',
                      ]"
                    >
                      {{
                        item.status && item.status.value == 1
                          ? "已采纳"
                          : "待审核"
                      }}
                    </div>
                  </div>
                  <router-link :to="`/harkenDetail?id=${item.id}`">
                    <div class="tbody">
                      <div class="words">{{ item.questionSolution }}</div>
                      <div class="else">
                        <p class="zan">
                          <img src="@/assets/img/harken-icon7.png" />{{
                            item.likeNums
                          }}
                        </p>
                        <p class="time">
                          <img src="@/assets/img/harken-icon9.png" />
                          建议于 {{ item.createTime }}
                        </p>
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
              <el-pagination
                v-if="sugListTotal > 0"
                background
                prev-text="上一页"
                next-text="下一页"
                :current-page.sync="currentPage"
                :total="sugListTotal"
                layout="prev, pager, next"
                @current-change="handleCurrentChange"
                style="text-align: center; margin: 15px 0"
              >
              </el-pagination>
              <div v-else class="noSuggestions">暂无建议</div>
            </div>
          </div>
        </div>
      </div>
      <div class="harken-rg">
        <button @click="openSug" style="margin-bottom: 12px">
          <img src="@/assets/img/harken-icon10.png" />提建议
        </button>
        <SideAdvertisement></SideAdvertisement>
      </div>
    </div>
    <el-dialog
      :visible.sync="dialogFormVisible"
      class="harken-dialog"
      title="我的建议"
    >
      <el-form :model="form" label-width="120px" :rules="sugRule" ref="form">
        <el-form-item label="问题类别" prop="type">
          <el-select v-model="form.type" placeholder="请选择问题类别">
            <el-option label="售前咨询" value="0"></el-option>
            <el-option label="面经建议" value="1"></el-option>
            <el-option label="打卡建议" value="2"></el-option>
            <el-option label="功能建议" value="3"></el-option>
            <el-option label="其他建议" value="4"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="建议标题" prop="questionTitle">
          <el-input
            v-model="form.questionTitle"
            placeholder="一句话描述你的问题"
          ></el-input>
        </el-form-item>
        <el-form-item label="建议描述" prop="questionDescribe">
          <el-input v-model="form.questionDescribe" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="建议解决方案" prop="questionSolution">
          <el-input v-model="form.questionSolution" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="上传附件">
          <el-upload
            ref="upload"
            :before-upload="beforeFileUpload"
            :on-success="handleFileSuccess"
            :limit="3"
            :on-remove="removeFile"
            :action="uploadUrl"
            class="upload-demo"
            multiple
            accept=".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP"
            style="display: inline-block"
            :data="{ isPublic: true }"
            :headers="{ 'X-Access-Token': token }"
          >
            <el-button icon="el-icon-paperclip" size="small" type="primary"
              >选择文件
            </el-button>
            <div
              slot="tip"
              class="el-upload__tip"
              style="color: rgba(0, 0, 0, 0.25)"
            >
              选择文件,允许的文件类型：png，jpg，gif，jpeg，bmp且不超过5MB
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button class="submit" type="primary" @click="submitSug"
          >发表建议
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getHarkenList, publishSug } from "../../service/harken";
import { mapState } from "vuex";
import SideAdvertisement from "@/components/sideAdvertisement";
export default {
  components: { SideAdvertisement },
  data() {
    return {
      activeTitle: "售前咨询",
      activeType: "0",
      token: window.localStorage.getItem("CSON_PAGE_TOKEN"),
      uploadUrl: process.env.VUE_APP_BASE_URL + "/common/upload",
      tabbars: [
        {
          icon: require("@/assets/img/harken-icon1.png"),
          iconCur: require("@/assets/img/harken-icon12.png"),
          label: "售前咨询",
          type: 0,
        },
        {
          icon: require("@/assets/img/harken-icon2.png"),
          iconCur: require("@/assets/img/harken-icon22.png"),
          label: "面经建议",
          type: 1,
        },
        {
          icon: require("@/assets/img/harken-icon4.png"),
          iconCur: require("@/assets/img/harken-icon42.png"),
          label: "打卡建议",
          type: 2,
        },
        {
          icon: require("@/assets/img/harken-icon5.png"),
          iconCur: require("@/assets/img/harken-icon52.png"),
          label: "功能建议",
          type: 3,
        },
        {
          icon: require("@/assets/img/harken-icon6.png"),
          iconCur: require("@/assets/img/harken-icon62.png"),
          label: "其他建议",
          type: 4,
        },
      ],
      TabCur: 0,
      dialogFormVisible: false,
      form: {
        type: "",
        questionTitle: "",
        questionDescribe: "",
        questionSolution: "",
        printscreen: [],
      },
      sugRule: {
        type: [
          { required: true, message: "请选择问题类别", trigger: "change" },
        ],
        questionTitle: [
          { required: true, message: "请填写标题", trigger: "blur" },
        ],
        questionDescribe: [
          { required: true, message: "请填写描述", trigger: "blur" },
        ],
      },
      fileList: [],
      sugList: [],
      currentPage: 1,
      sugListTotal: 0,
      sugFiles: [],
    };
  },
  mounted() {
    this.getHarkenList({ type: 0, current: 1 });
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  methods: {
    getHarkenList(data) {
      getHarkenList(data).then((res) => {
        this.sugList = res.result.records;
        this.sugListTotal = res.result.total;
      });
    },
    handleCurrentChange(page) {
      getHarkenList({ type: this.activeType, current: page }).then((res) => {
        this.sugList = res.result.records;
        this.sugListTotal = res.result.total;
        this.$refs.sugList.scrollIntoView();
      });
    },
    tabSelect(item, index) {
      this.TabCur = index;
      this.activeTitle = item.label;
      this.currentPage = 1;
      this.getHarkenList({ type: item.type, current: 1 });
    },
    open() {
      this.$message("这是一条消息提示");
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    like(index) {
      // if (!this.sugList[index].iszan) {
      //   this.$message("您点赞了...");
      //   this.sugList[index].iszan = true;
      // }
      !this.sugList[index].iszan && this.$message("您点赞了...");
      this.sugList[index].iszan = !this.sugList[index].iszan;
    },
    replay(index) {
      this.sugList[index].replay = !this.sugList[index].replay;
    },
    removeFile(file, fileList) {
      console.log(file.response.result.objectKey);
      let removeIndex = this.sugFiles.indexOf(file.response.result.objectKey);
      this.sugFiles.splice(removeIndex, 1);
    },
    beforeFileUpload(file) {
      if (this.sugFiles.length >= 3) {
        return this.$message.error("最多只能上传3个文件!");
      }
      const isLt10M = file.size / 1024 / 1024 < 10;

      if (!isLt10M) {
        this.$message.error("上传文件大小不能超过10MB!");
      }
      return isLt10M;
    },
    handleFileSuccess(res) {
      console.log(res);
      this.sugFiles.push(res.result.objectKey);
    },
    openSug() {
      if (!this.userInfo || !this.userInfo.id) {
        this.$router.push("/registerform");
        return;
      }
      this.dialogFormVisible = true;
    },
    submitSug() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          publishSug({ ...this.form, printscreen: this.sugFiles }).then(
            (res) => {
              if (res.success) {
                this.$message.success("提交成功，我们会尽快处理");
                this.dialogFormVisible = false;
                this.$refs.form.resetFields();
                this.$refs.upload.clearFiles();
                this.sugFiles = [];
              }
            }
          );
        }
      });
    },
  },
};
</script>

<style scoped>
.imgs a img {
  border-radius: 4px;
  margin-bottom: 12px;
}
.list-container {
  border-top: 1px dashed #999;
}
.aside-zx {
  background: #ffffff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  margin-top: 10px;
  padding: 22px 0;
  text-align: center;
}

.aside-zx-tt {
  font-size: 14px;
  color: #34495e;
  margin-bottom: 6px;
  text-align: center;
}
.zx-ewm {
  border-radius: 2px;
  border: 3px solid #0075f6;
  width: 106px;
  height: 106px;
  margin: 0 auto;
  font-size: 0;
}
.aside-course {
  font-size: 0;
  margin-bottom: 10px;
}
.aside-course:hover {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}
.words {
  display: -webkit-box;
  overflow: hidden;
  white-space: normal !important;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #fa6400;
}
.noSuggestions {
  padding: 50px 0;
  margin: 0 auto;
  text-align: center;
  font-size: 24px;
}
</style>
