// 倾听
import request from '../utils/request';
// 获取倾听列表
export async function getHarkenList(params) {
    try {
        return await request({
            url: '/question',
            method: 'get',
            params
        });
    } catch (error) {
        return {};
    }
}
// 发表建议
export async function publishSug(data) {
    try {
        return await request({
            url: '/question',
            method: 'post',
            data
        });
    } catch (error) {
        return {};
    }
}
// 获取建议详情
export async function getSugDetail(id) {
    try {
        return await request({
            url: `/question/${id}`,
            method: 'get'
        });
    } catch (error) {
        return {};
    }
}
// 获取建议评论列表
export async function getSugDetailComments(id, page) {
    try {
        return await request({
            url: `/publicComment/comment/${id}?current=${page?page:1}&type=3`,
            method: 'get'
        });
    } catch (error) {
        return {};
    }
}
// 发布评论
export async function publishComment(data) {
    try {
        return await request({
            url: `/publicComment/comment`,
            method: 'post',
            data
        });
    } catch (error) {
        return {};
    }
}
// 获取建议子级评论列表
export async function getHarkenChildComments(commentId, page) {
    try {
        return await request({
            url: `/publicComment/commentChildren/${commentId}?current=${page? page :1}`,
            method: 'get'
        });
    } catch (error) {
        return {};
    }
}
// 发布倾听评论
export async function publishHarkenDetailComment(data) {
    try {
        return await request({
            url: `/publicComment/comment`,
            method: 'post',
            data
        });
    } catch (error) {
        return {};
    }

}